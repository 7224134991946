import { createSharedComposable } from '@vueuse/core'
import type { User } from '~/types'
import { UserRole } from '~/enums'

const _useRbac = () => {
  const user = useState<User>('auth:data')
  const route = useRoute()

  const can = (
    permission: string,
    userId: number | undefined = undefined
  ): boolean => {
    if (!user.value || !Array.isArray(user.value.roles)) {
      return false
    }

    if (user.value.roles.includes(UserRole.SuperAdmin) || user.value.roles.includes(UserRole.Admin)) {
      return true
    }

    const [ module ] = permission.split('.')
    if (!module) {
      return false
    }

    if (!user.value.permissions.includes(permission)) {
      return false
    }

    if (user.value.roles.includes(UserRole.Writer)) {
      return true
    }

    if (userId) {
      return userId === user.value.id
    }

    return true
  }

  const canEditField = (field: string) => {
    return true
  }

  return {
    user,
    can,
    canEditField,
  }
}

export const useRbac = createSharedComposable(_useRbac)
